<template>
  <div class="pumpmodparams">            
    <v-container>
      <v-row>
        <v-col cols="12" sm="7">
          <h3>Pump  Modified Parameters - {{ pumpcname }}</h3>
          {{ pumpnname }} (<a :href="`pumphealth?pumpip=${pumpip}`">{{ pumpip }}</a>)<br/>
          <v-btn @click.prevent="goto_pumpreport_page(pumpip)">PumpHealth</v-btn>
        </v-col>
        <v-col cols="6" sm="3">
            <v-menu v-model="searchreqdatemenu" :close-on-content-click="false" :nudge-right="40" 
            transition="scale-transition" offset-y min-width="auto" >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="requestdate" readonly v-bind="attrs" v-on="on"
                label="Select Report Date" prepend-icon="mdi-calendar"                    
              ></v-text-field>
            </template>
            <v-date-picker v-model="requestdate" @input="searchreqdatemenu = false" ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="2">
          <v-btn color="primary" @click.prevent="get_modified_params(pumpip,requestdate)">Get Report</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="pumpcname != null">
      <v-row>
        <v-col>
          <h3>LastSeen Modified Parameters</h3> 
        </v-col>
        <v-col><v-text-field v-model="nondefsearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table :headers="nondefheaders" :items="nondefaultslist" :search="nondefsearch"
            :footer-props="{'items-per-page-options':[5, 10, 30, -1]}" :items-per-page="10" dense
            must-sort :sort-by.sync="sortNonDefsBy" :sort-desc.sync="sortNonDefsDesc" >
            <template #item.instref="{ item }">
              <a @click="get_param_history(item.instref)">{{ item.instref }}</a>
            </template>
            <!--template #item.defvalue="{ item }">
              {{ item.defvalue }}
            </template>
            <template #item.currvalue="{ item }">
              {{ item.currvalue }}
            </template-->
            <template #item.datetime="{ item }">
              {{ convertUTCDateToSortableLocalDate(item.datetime) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <span v-else>{{ report_text }}</span>

    <v-container fluid v-if="paramhistlist != null">
      <v-row>
        <v-col>
          <h3>Parameter Change History</h3> 
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table :headers="paramhistheaders" :items="paramhistlist"
            :footer-props="{'items-per-page-options':[5, 10, 30, -1]}" :items-per-page="10" dense
            must-sort :sort-by.sync="sortParamHistBy" :sort-desc.sync="sortParamHistDesc" >
            <!--template #item.defvalue="{ item }">
              {{ item.defvalue }}
            </template>
            <template #item.currvalue="{ item }">
              {{ item.currvalue }}
            </template-->
            <template #item.datetime="{ item }">
              {{ convertUTCDateToSortableLocalDate(item.datetime) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <span v-else>{{ param_history_text }}</span>
  </div>
</template>

<script>
  import dateutils from '../plugins/date_utilities';

  export default {
    name: 'PumpModifiedParams',
    data () {
      return {
        loading: true,
        pumpip:null,pumpcname:null,pumpnname:null,
        searchreqdatemenu: false,
        //requestdate: "2000-01-01", 
        requestdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        
        report_text:"",

        nondefsearch: "",
        nondefheaders:[
          { text: 'Parameter', value: 'instref', filterable: true, sortable: true },
          { text: 'ProfileHash', value: 'profilehash', filterable: false, sortable: false },
          { text: 'Index', value: 'index', filterable: true, sortable: true },
          { text: 'Datetime', value: 'datetime', filterable: false, sortable: true },
          { text: 'Default Value', value: 'defvalue', filterable: false, sortable: false },
          { text: 'Current Value', value: 'currvalue', filterable: false, sortable: false },
          { text: 'units', value: 'units', filterable: false, sortable: false }
        ],
        sortNonDefsBy: ['instref',"datetime"], sortNonDefsDesc: true,
        nondefaultslist:[],



        paramhistsearch: "",
        paramhist_report_text:"Click on Parameter to retrieve history...",
        paramhistheaders:[
          { text: 'Parameter', value: 'instref', filterable: true, sortable: true },
          { text: 'ProfileHash', value: 'profilehash', filterable: false, sortable: false },
          { text: 'Index', value: 'index', filterable: true, sortable: true },
          { text: 'Datetime', value: 'datetime', filterable: false, sortable: true },
          { text: 'Default Value', value: 'defvalue', filterable: false, sortable: false },
          { text: 'Current Value', value: 'currvalue', filterable: false, sortable: false },
          { text: 'units', value: 'units', filterable: false, sortable: false }
        ],
        sortParamHistBy: "datetime", sortParamHistDesc: true,
        paramhistlist:null,
      }
    },
    methods: {
      get_modified_params(pumpip,datestr) {
        var utcdatestr = this.convertLocalDateToUTCDate(datestr);

        this.$api.post(this.$olsupervisorurl + '/health/modparams', 
        {'code':'get_modified_params', "pumpip":this.$route.query["pumpip"], "date":utcdatestr})
        .then(response => {
          //console.log(response.data);
          if (response.data['code'] == 'get_modified_params') {
            if (response.data['success'])
            {
              const modparamsreport = response.data['paramsdict'];
              if (modparamsreport != null) {
                this.pumpip = modparamsreport['pumpip'];
                this.pumpcname = modparamsreport['cname'];
                this.pumpnname = modparamsreport['nname'];
                this.nondefaultslist = modparamsreport['modparams']
              } else {
                this.pumpip = this.$route.query["pumpip"];
                this.pumpcname = null;
                this.pumpnname = null;
                this.report_text = "Data not available!";
              }
            } else {
              this.report_text = this.$route.query["pumpip"] + " - " + response.data['errmsg'];
              this.pumpcname = null;
              this.pumpnname = null;
            }
          } else {
            this.pumpcname = null;
            this.pumpnname = null;
            this.report_text = "Invalid response!";
          }
        });
      },
      get_param_history(instref) {
        var utcdatestr = this.convertLocalDateToUTCDate(this.requestdate);

        this.$api.post(this.$olsupervisorurl + '/health/paramhistory', 
        {'code':'get_modparam_history', "pumpip":this.$route.query["pumpip"], "instref":instref, "date":utcdatestr})
        .then(response => {
          //console.log(response.data);
          if (response.data['code'] == 'get_modparam_history') {
            if (response.data['success'])
            {
              const paramhistreport = response.data['historydict'];
              if (paramhistreport != null) {
                //this.pumpip = paramhistreport['pumpip'];
                this.paramhistlist = paramhistreport['histlist']
              } else {
                //this.pumpip = this.$route.query["pumpip"];
                this.paramhistlist = null;
                this.paramhist_report_text = "Data not available!";
              }
            } else {
              this.paramhistlist = null;
              this.paramhist_report_text = this.$route.query["pumpip"] + " - " + response.data['errmsg'];
            }
          } else {
            this.paramhistlist = null;
            this.paramhist_report_text = "Invalid response!";
          }
        });
      },

      goto_pumpreport_page(ipaddr) {
        //console.log("goto_pumpreport_page", ipaddr);
        this.$router.push({ path: '/pumphealth', query: { "pumpip": ipaddr } });
      },
    },
    created(){
      this.convertLocalDateToUTCDate = dateutils.convertLocalDateToUTCDate;
      //this.convertUTCDateToLocalDate = dateutils.convertUTCDateToLocalDate;
      this.convertUTCDateToSortableLocalDate = dateutils.convertUTCDateToSortableLocalDate;
    },
    mounted(){
      const pumpip = this.$route.query["pumpip"];
      this.get_modified_params(pumpip,this.requestdate);
    },    
  }
</script>
<style scoped>
@media print {
    .v-btn, .v-input {
        visibility: hidden;
    }
}
</style>