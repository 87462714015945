<template>
<div id="pumpsearch" :style="{'height':dataCardHeight+'px','min-height':'240px'}">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Pumps Search</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >

      <v-col class="gtm-col-padding  fill-height" cols="12">
        <v-card class="gtm-card-scroll fill-height"  style="overflow-y: scroll">
        <v-card-title>
          <v-text-field  v-model="pumpip_to_search" append-icon="mdi-magnify" class="shrink"
            filled dense hide-details :rules="[v => v.length<=15 || 'Max 15 characters']" counter>
          </v-text-field>
          &nbsp;
          <v-btn class="primary fill-height" @click="searchPumps">Search</v-btn>
        </v-card-title>
        <v-card-subtitle>
          <v-card-text><h3>Found Pumps ({{ num_found_pumps }})</h3></v-card-text>
        </v-card-subtitle>
        <v-scroll-y-transition mode="out-in">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center auto-collapse" style="width:5%"> Add to VHMI </th>
                    <th class="text-center auto-collapse" style="width:5%"> Add to Reporter </th>
                    <th class="text-center auto-collapse" style="width:5%" v-if="view_pumpreporter_vars"> ReporterVars </th>
                    <th class="text-center" style="width:15%"> IP Address (Eventlog)</th>
                    <th class="text-left" style="width:10%"> Nickname </th>
                    <th class="text-left" style="width:10%"> Customer </th>
                    <th class="text-left" style="width:50%"> Reference Path </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pump,idx in pumpslist" :key="idx" >
                    <td v-if="num_found_pumps>0" class="text-center auto-collapse">
                      <v-btn v-if="pump.onvhmi" dark small color="error">
                        <v-icon light > mdi-checkbox-marked-outline </v-icon>
                      </v-btn>
                      <v-btn v-else dark small color="primary" @click="add_pump_to_vhmi(pump.id,pump.ip)">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td v-if="num_found_pumps>0" class="text-center auto-collapse">
                      <v-btn v-if="pump.onreporter" dark small color="error" @click="del_pump_from_reporter(pump.id,pump.ip)">
                        <v-icon light > mdi-minus </v-icon>
                      </v-btn>
                      <v-btn v-else dark small color="primary" @click="add_pump_to_reporter(pump.id,pump.ip)">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td v-if="view_pumpreporter_vars && (num_found_pumps>0)" class="text-center auto-collapse">
                      <v-btn dark small color="green" @click="goto_pumpreporter_varspage(pump.id)">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td v-if="num_found_pumps>0" class="text-center">
                      <v-btn x-small color="primary" @click="goto_eventssummary_page(pump.ip)" height="30px">
                        <v-icon > mdi-alpha-s-circle-outline </v-icon>
                      </v-btn>
                      <span class="smart-collapse">&nbsp;</span>
                      <a class="smart-collapse" :href="`events?pumpip=${pump.ip}`">{{ pump.ip }}</a>
                      <template v-if="view_health_link">
                        <v-btn x-small color="primary" @click="goto_pumphealth_page(pump.ip)" height="30px">
                          <v-icon > mdi-alpha-h-circle-outline </v-icon>
                        </v-btn>
                      </template>
                      <a class="smart-collapse" :href="`events?pumpip=${pump.ip}`">{{ pump.ip }}</a>
                    </td>
                    <td class="text-left">{{ pump.nname }}</td>
                    <td class="text-left">{{ pump.cname }}</td>
                    <td> {{ pathListToString(pump.pumppath) }} </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import pumphooks from '../plugins/pump_data_hooks'
  export default {
    name: "PumpSearchPage",

    data: () => ({
      dataCardHeight:0,

      pumpip_to_search: "172.16.0.0",      
      pumpslist: [],
      num_found_pumps: 0,
      pumps_in_reporter: [],

      view_pumpreporter_vars: null
    }),
    created() {
      this.resizeHandler();
      window.addEventListener("resize", this.resizeHandler);

      //this.pumps_in_reporter = pumphooks.pumps_in_reporter;
      this.get_pumps_in_reporter = pumphooks.get_pumps_in_reporter;
      this.add_pump_to_vhmi = pumphooks.add_pump_to_vhmi;
      this.pumps_onvhmi_check = pumphooks.pumps_onvhmi_check;
      this.pumps_onreporter_check = pumphooks.pumps_onreporter_check;
      this.add_pump_to_reporter = pumphooks.add_pump_to_reporter;
      this.del_pump_from_reporter = pumphooks.del_pump_from_reporter;
      this.goto_pumpreporter_varspage = pumphooks.goto_pumpreporter_varspage;
    },
    //destroyed() {
    beforeDestroy() {
      window.removeEventListener("resize", this.resizeHandler);
    },
    mounted() {
      //this.$emit('loadstart');
      const useraccessdict = this.$store.getters.get_useraccessdict();
      //console.log(useraccessdict);
      this.view_pumpreporter_vars = (useraccessdict != null) && useraccessdict["edit_reporter_vars"];
      
      this.get_pumps_in_reporter();
    },
    computed:{
      view_health_link(){
        const useraccessdict = this.$store.getters.get_useraccessdict();
        return (useraccessdict != null) && useraccessdict["addpumps"];
      },
    },
    methods: {
      resizeHandler(){//e
        const topbarHeight = 64;
        //const cardHeight = e.target.innerHeight-topbarHeight;
        const cardHeight = window.innerHeight-topbarHeight;
        //console.log(e.target.innerHeight,cardHeight);
        this.dataCardHeight = cardHeight;
      },
      searchPumps () {
        //console.log(this.pumpip_to_search)
        this.pumpslist = [];
        this.num_found_pumps = 0;
        //console.log("test");

        this.$api.post(this.$orgmanurl + '/pumps/search',{"pumpip":this.pumpip_to_search})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "search_company_pumps")
                {
                  this.pumpslist = response.data["found-pumps-list"];
                  //console.log(this.pumpslist);
                  
                  if (this.pumpslist != null) {
                    this.pumps_onvhmi_check();
                    this.pumps_onreporter_check();
                  }
                  else this.pumpslist = [];
                  this.num_found_pumps = this.pumpslist.length;
                  if (this.pumpslist.length == 0) this.pumpslist = [{nname:"Not Found!", ip:this.pumpip_to_search, pumppath:[]}];
                }
                //this.$emit('loadcomplete');
              });

        return;
      },
      pathListToString(pathlist) {
        return pathlist.join(' -> ')
      },
      goto_eventssummary_page(ipaddr) {
        //console.log("goto_eventssummary_page", ipaddr);
        this.$router.push({ path: '/eventsum', query: { "pumpip": ipaddr } });
      },
      goto_pumphealth_page(ipaddr) {
        //console.log("goto_eventssummary_page", ipaddr);
        this.$router.push({ path: '/pumphealth', query: { "pumpip": ipaddr } });
      },
    }
  }
</script>
<style scoped>
table>tbody>tr>td {
  padding: 0px;
}
@media (min-width:1600px){
  table>tbody>tr>td>a.smart-collapse:nth-of-type(1) {
    display:inline-block;
    width:8em;
  }
  table>tbody>tr>td>a.smart-collapse:nth-of-type(2) {
    display:none;
  }
  table>tbody>tr>td>span.smart-collapse {
    display:none;
  }
}
@media (max-width:960px){
  .auto-collapse{
    display:none;
  }
}
@media (max-width:1600px){
  table>tbody>tr>td>a.smart-collapse:nth-of-type(1) {
    display:none;
  }
  table>tbody>tr>td>a.smart-collapse:nth-of-type(2) {
    display:inline-block;
    width:8em;
  }
}

.gtm-col-padding {
  padding: 3px;
}

*::-webkit-scrollbar {
  width: 3px;
} 
*::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
  
  border-radius: 3px;
} 
*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  /*outline: 1px solid slategrey;*/
  border-radius: 3px;
}
</style>